import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Wrapper, Heading, Typography, Button } from '../../ui';
import { Link } from 'gatsby';
import PartnerItem from './PartnerItem';

const Partner = ({ section = {} }) => {
  return (
    <Wrapper py={10} bgColor="level1">
      <Container>
        <Heading inverse>{section.title}</Heading>
        <Wrapper mt="-45px" mb={8}>
          <Typography variant="body1" align="center">
            {section.subtitles && section.subtitles[0]}
          </Typography>
        </Wrapper>
        <Grid container>
          {section.subSections &&
            section.subSections.map((subSection, index) => (
              <Grid key={index} item xs={12} md={4}>
                <PartnerItem partner={subSection} index={index} />
              </Grid>
            ))}
        </Grid>
      </Container>
      <Wrapper textAlign="center" mt={3}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/request-partnership"
        >
          Request Partnership
        </Button>
      </Wrapper>
    </Wrapper>
  );
};

export default Partner;
