import React, { useState, useEffect } from 'react';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { TextField, FieldErrorMsg, Wrapper, Button, Select } from '../../ui';
import {
  averageTransactionOptions,
  monthlyAverageOptions,
} from '../../../config/variables';
import { useEmailVerify } from '../../../hooks';

const prepareOptions = (countries) => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
      dialCode: country.dialCode,
    };
  });
};

const BasicInfo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleBack,
  country,
  isCreatingLead,
  setFieldTouched,
}) => {
  const [dialCode, setDialCode] = useState('');
  const {
    emailData,
    handleEmailVerify,
    emailError,
    emailVerifyLoading,
  } = useEmailVerify();

  const handleCountryChange = (option) => {
    setDialCode(option.dialCode);
    setFieldValue('countryName', option.value);
  };

  const handleEmailBlur = async (event) => {
    setFieldTouched('email', true);

    if (!errors.email) {
      handleEmailVerify(event.target.value);
    }
  };

  useEffect(() => {
    setFieldValue('isEmailVerified', emailData.emailVerified);
  }, [setFieldValue, emailData.emailVerified]);

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '70%' }}>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Full Name"
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              error={errors.fullName && touched.fullName}
              variant="outlined"
              color="secondary"
            />
            <FieldErrorMsg
              error={errors.fullName}
              isTouched={touched.fullName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleEmailBlur}
              error={
                (errors.email && touched.email) ||
                (emailData.emailRejected && touched.email)
              }
              variant="outlined"
              color="secondary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {emailVerifyLoading && <CircularProgress size={20} />}
                  </InputAdornment>
                ),
              }}
            />
            <FieldErrorMsg
              error={errors.email || emailData.emailRejected}
              message={emailError}
              isTouched={touched.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Business Name"
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.businessName && touched.businessName}
              variant="outlined"
              color="secondary"
            />
            <FieldErrorMsg
              error={errors.businessName}
              isTouched={touched.businessName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              options={prepareOptions(country.data || [])}
              placeholder="Country"
              mr={1}
              name="countryName"
              onChange={handleCountryChange}
            />
            <FieldErrorMsg
              error={errors.countryName}
              isTouched={touched.countryName}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={values.phone}
              size="small"
              pl={1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone && touched.phone}
              variant="outlined"
              color="secondary"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{dialCode}</InputAdornment>
                ),
              }}
            />
            <FieldErrorMsg error={errors.phone} isTouched={touched.phone} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Preferred Date & Time (if any)"
              name="bestTimeToContact"
              value={values.bestTimeToContact}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.bestTimeToContact && touched.bestTimeToContact}
              variant="outlined"
              color="secondary"
            />
            <FieldErrorMsg
              error={errors.bestTimeToContact}
              isTouched={touched.bestTimeToContact}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="small"
              label="Previous POS (optional)"
              name="prevPOS"
              value={values.prevPOS}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.prevPOS && touched.prevPOS}
              variant="outlined"
              color="secondary"
            />
            <FieldErrorMsg error={errors.prevPOS} isTouched={touched.prevPOS} />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.needCardProcessing}
                  onChange={(event) =>
                    setFieldValue('needCardProcessing', event.target.checked)
                  }
                  value={values.needCardProcessing}
                />
              }
              label="I need Integrated Card Processing"
            />
            <FieldErrorMsg
              error={errors.needCardProcessing}
              isTouched={touched.needCardProcessing}
            />
          </Grid>
          {values.needCardProcessing && (
            <>
              <Grid item xs={12} md={6}>
                <Select
                  options={monthlyAverageOptions}
                  placeholder="Monthly Volume"
                  mr={1}
                  name="monthlyVolume"
                  onChange={(option) =>
                    setFieldValue('monthlyVolume', option.value)
                  }
                />
                <FieldErrorMsg
                  error={errors.monthlyVolume}
                  isTouched={touched.monthlyVolume}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={averageTransactionOptions}
                  placeholder="Average Transaction"
                  mr={1}
                  name="averageTransaction"
                  onChange={(option) =>
                    setFieldValue('averageTransaction', option.value)
                  }
                />
                <FieldErrorMsg
                  error={errors.averageTransaction}
                  isTouched={touched.averageTransaction}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Wrapper display="flex" justifyContent="space-between" mt={8}>
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            loading={isCreatingLead}
            disabled={emailData.emailRejected || isCreatingLead}
            color="secondary"
            type="submit"
          >
            Send Request
          </Button>
        </Wrapper>
      </Form>
    </Wrapper>
  );
};

const BasicInfoForm = withFormik({
  mapPropsToValues: ({
    formData: {
      fullName,
      businessName,
      prevPOS,
      needCardProcessing,
      email,
      phone,
      countryName,
      monthlyVolume,
      averageTransaction,
      bestTimeToContact,
      isEmailVerified,
    },
  }) => ({
    fullName: fullName || '',
    businessName: businessName || '',
    prevPOS: prevPOS || '',
    email: email || '',
    phone: phone || '',
    countryName: countryName || '',
    needCardProcessing: needCardProcessing || false,
    isEmailVerified: isEmailVerified || false,
    bestTimeToContact: bestTimeToContact || '',
    monthlyVolume: monthlyVolume || '',
    averageTransaction: averageTransaction || '',
  }),
  validationSchema: yup.object().shape({
    fullName: yup.string().required('Please enter your Name'),
    businessName: yup.string().required('Please enter your Business Name'),
    prevPOS: yup.string(),
    bestTimeToContact: yup.string(),
    needCardProcessing: yup.boolean(),
    monthlyVolume: yup.string().when('needCardProcessing', {
      is: (needCardProcessing) => needCardProcessing,
      then: yup.string().required('Please select monthly volume'),
      otherwise: yup.string(),
    }),
    averageTransaction: yup.string().when('needCardProcessing', {
      is: true,
      then: yup.string().required('Please select average transaction'),
      otherwise: yup.string(),
    }),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
    phone: yup
      .number()
      .typeError('Please enter only number')
      .integer('Please enter only number'),
    countryName: yup.string(),
  }),
  handleSubmit(values, { props: { handleFormData } }) {
    handleFormData(values);
  },
})(BasicInfo);

export default BasicInfoForm;
