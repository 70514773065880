import React, { useState, useEffect } from 'react';
import { Container, Stepper, Step, StepLabel } from '@material-ui/core';
import { Wrapper, Typography } from '../../ui';
import { useFetch } from '../../../hooks';
import { BasicInfo, BusinessType, Feedback } from './';
import { navigate } from 'gatsby';
import queryString from 'query-string';

const stepProgress = [1, 2];
const syncSteps = [1];

export default function Download({ sections = [], location }) {
  const [formData, setFormData] = useState({});
  const [lead, setLead] = useState({});
  const fetchCountry = useFetch('countries');
  const createLead = useFetch('leads', 'POST');
  const query = queryString.parse(location.search);
  const activeStep = Number(query.step) || 1;
  const [currentStep, setCurrentStep] = useState(1);
  const notifyZD = useFetch(`zd/leads/${lead.id}`, 'POST');

  useEffect(() => {
    setCurrentStep(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!lead.isTest && lead.id) {
      notifyZD.sendReq();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead.id]);

  useEffect(() => {
    fetchCountry.sendReq();

    if ([1, 2].includes(activeStep)) {
      setFormData(query);
    } else {
      setFormData({
        businessType: query.businessType,
        step: 2,
      });
      navigate(
        `${location.pathname}?${queryString.stringify({
          businessType: query.businessType,
          step: 2,
        })}`
      );

      setCurrentStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (params) => {
    const step = currentStep + 1;

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...params,
        step: step,
      })}`
    );

    setCurrentStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...formData,
        step: step,
      })}`
    );

    setCurrentStep(step);
  };

  const handleFormData = async (values) => {
    const updatedFormData = {
      ...formData,
      ...values,
    };
    setFormData(updatedFormData);

    if (syncSteps.includes(currentStep)) {
      handleNext(updatedFormData);
    }

    if (currentStep === 2) {
      try {
        const lead = await createLead.sendReq({
          ...updatedFormData,
          type: 'REQUEST_ONLINE_DEMO',
        });
        setLead(lead.data);
        if (lead.status === 201) {
          navigate(
            `${location.pathname}?${queryString.stringify({
              ...updatedFormData,
              step: 3,
            })}`
          );

          setCurrentStep(3);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return (
          <BusinessType
            section={sections && sections[1]}
            formData={formData}
            handleFormData={handleFormData}
          />
        );
      case 2:
        return (
          <BasicInfo
            section={sections && sections[3]}
            formData={formData}
            handleFormData={handleFormData}
            handleNext={handleNext}
            handleBack={handleBack}
            country={fetchCountry}
            isCreatingLead={createLead.loading}
          />
        );
      case 3:
        return <Feedback />;
      default:
        return;
    }
  }

  return (
    <Wrapper my={6}>
      <Container>
        <Wrapper my={4} width={{ xs: '100%', md: '60%' }} mx="auto">
          <Typography variant="h2" align="center" mb={2}>
            {/* {sections && sections[0] && sections[0].title} */}
            Request Online Demo
          </Typography>
          <Typography variant="body1" align="center" mb={1}>
            {/* {sections && sections[0] && sections[0].subtitles[0]} */}
          </Typography>
          <Typography variant="body1" align="center">
            {sections && sections[0] && sections[1].subtitles[1]}
          </Typography>
        </Wrapper>
      </Container>
      <Wrapper width={{ xs: '100%', md: '40%' }} mx="auto">
        <Stepper
          activeStep={currentStep - 1}
          alternativeLabel
          style={{ background: '#fff' }}
        >
          {stepProgress.map((_, index) => (
            <Step key={index}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </Wrapper>
      <Container>
        <Wrapper mb={6} mt={3}>
          {getStepContent(currentStep)}
        </Wrapper>
      </Container>
    </Wrapper>
  );
}
