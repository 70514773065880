import React from 'react';
import { Header, SEO, Footer } from '../views/features';
import Theme from '../styles';

const Layout = ({ SEOTitle, metaData, menus, children }) => {
  return (
    <Theme>
      <SEO title={SEOTitle} metaData={metaData} />
      <Header menus={menus} />
      {children}
      <Footer metaData={metaData} menus={menus} />
    </Theme>
  );
};

export default Layout;
