import { useState } from 'react';
import axios from 'axios';
import { apiEndpoint } from '../config/variables';

const useFetch = (url, method = 'GET') => {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendReq = async (data, params = '') => {
    setLoading(true);

    try {
      const response = await axios({
        method: method,
        url: apiEndpoint + '/' + url + params,
        data,
      });

      setData(response.data.data);
      setStatus(response.status);
      setLoading(false);

      return {
        data: response.data.data,
        status: response.status,
      };
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return { sendReq, data, error, loading, status };
};

export default useFetch;
