import React from 'react';
import { Container } from '@material-ui/core';
import { Heading, Wrapper } from '../../ui';
import SecondaryProduct from './SecondaryProduct';

const Products = ({ section = {} }) => {
  return (
    <Wrapper bgColor="#FFFCF4" pt={10} pb={8}>
      <Container>
        <Heading>{section.title}</Heading>
        {section.subSections &&
          section.subSections.map((subSection, index) => (
            <SecondaryProduct key={index} index={index} product={subSection} />
          ))}
      </Container>
    </Wrapper>
  );
};

export default Products;
