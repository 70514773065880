import React from 'react';
import { Typography, Wrapper } from '../../ui';

const Feedback = () => {
  return (
    <Wrapper>
      <Typography variant="h3" mb={2} mt={3} align="center">
        Your request has been submitted.
      </Typography>
      <Typography variant="h4" mb={1} align="center">
        Our team will review and contact you soon.
      </Typography>
    </Wrapper>
  );
};

export default Feedback;
