import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment, CircularProgress } from '@material-ui/core';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  FieldErrorMsg,
  Wrapper,
  Button,
  Select,
  Typography,
} from '../../ui';
import { useEmailVerify } from '../../../hooks';

const prepareOptions = (countries) => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
      dialCode: country.dialCode,
    };
  });
};

const BasicInfo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleBack,
  countries = [],
  isCreatingLead,
  setFieldTouched,
  location = {},
  countryDefaultOption,
}) => {
  const [dialCode, setDialCode] = useState('');
  const {
    emailData,
    handleEmailVerify,
    emailError,
    emailVerifyLoading,
  } = useEmailVerify();

  const handleCountryChange = (option) => {
    setDialCode(option.dialCode);
    setFieldValue('countryName', option.value);
  };

  const handleEmailBlur = async (event) => {
    setFieldTouched('email', true);

    if (!errors.email) {
      handleEmailVerify(event.target.value);
    }
  };

  useEffect(() => {
    setFieldValue('isEmailVerified', emailData.emailVerified);
  }, [setFieldValue, emailData.emailVerified]);

  useEffect(() => {
    setFieldValue('city', location.city);
    setFieldValue('countryName', countryDefaultOption.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.city, countryDefaultOption]);

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '70%' }}>
      <Form>
        <Wrapper
          border={1}
          p={2}
          borderRadius="8px"
          borderColor="#cdcdcd"
          mb={3}
        >
          <Typography varian="h2" mb={2}>
            Contact Person
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Full Name"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                error={errors.fullName && touched.fullName}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.fullName}
                isTouched={touched.fullName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                error={
                  (errors.email && touched.email) ||
                  (emailData.emailRejected && touched.email)
                }
                variant="outlined"
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {emailVerifyLoading && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                }}
              />
              <FieldErrorMsg
                error={errors.email || emailData.emailRejected}
                message={emailError}
                isTouched={touched.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Position"
                name="position"
                value={values.position}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.position && touched.position}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.position}
                isTouched={touched.position}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={values.phone}
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.phone && touched.phone}
                variant="outlined"
                color="secondary"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {dialCode || location.dialCode}
                    </InputAdornment>
                  ),
                }}
              />
              <FieldErrorMsg error={errors.phone} isTouched={touched.phone} />
            </Grid>
          </Grid>
        </Wrapper>

        <Wrapper
          border={1}
          p={2}
          borderRadius="8px"
          borderColor="#cdcdcd"
          mb={3}
        >
          <Typography varian="h2" mb={2}>
            Business Information
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Business Name"
                name="businessName"
                value={values.businessName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.businessName && touched.businessName}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.businessName}
                isTouched={touched.businessName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Web Site"
                name="website"
                value={values.website}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.website && touched.website}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.website}
                isTouched={touched.website}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                options={prepareOptions(countries || [])}
                placeholder="Country"
                mr={1}
                name="countryName"
                onChange={handleCountryChange}
                defaultValue={countryDefaultOption}
              />
              <FieldErrorMsg
                error={errors.countryName}
                isTouched={touched.countryName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="City"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.city && touched.city}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg error={errors.city} isTouched={touched.city} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="State"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.state && touched.state}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg error={errors.state} isTouched={touched.state} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Street Address"
                name="street"
                value={values.street}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.street && touched.street}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg error={errors.street} isTouched={touched.street} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Postal Code"
                name="postalCode"
                value={values.postalCode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.postalCode && touched.postalCode}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.postalCode}
                isTouched={touched.postalCode}
              />
            </Grid>
          </Grid>
        </Wrapper>

        <Wrapper
          border={1}
          p={2}
          borderRadius="8px"
          borderColor="#cdcdcd"
          mb={3}
        >
          <Typography varian="h2" mb={2}>
            Other Information
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Current POS Software"
                name="currentPos"
                value={values.currentPos}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.currentPos && touched.currentPos}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.currentPos}
                isTouched={touched.currentPos}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                label="Number of Installation"
                name="totalInstallation"
                value={values.totalInstallation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.totalInstallation && touched.totalInstallation}
                variant="outlined"
                color="secondary"
              />
              <FieldErrorMsg
                error={errors.totalInstallation}
                isTouched={touched.totalInstallation}
              />
            </Grid>
          </Grid>
        </Wrapper>

        <Wrapper display="flex" justifyContent="space-between" mt={8}>
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            loading={isCreatingLead}
            disabled={emailData.emailRejected || isCreatingLead}
            color="secondary"
            type="submit"
          >
            Send Request
          </Button>
        </Wrapper>
      </Form>
    </Wrapper>
  );
};

const BasicInfoForm = withFormik({
  mapPropsToValues: ({
    formData: {
      fullName,
      email,
      businessName,
      website,
      countryName,
      phone,
      street,
      city,
      state,
      postalCode,
      currentPos,
      totalInstallation,
      isEmailVerified,
      position,
      location = {},
    },
  }) => ({
    fullName: fullName || '',
    email: email || '',
    businessName: businessName || '',
    website: website || '',
    countryName: countryName || '',
    phone: phone || '',
    street: street || '',
    city: city || '',
    state: state || '',
    postalCode: postalCode || '',
    currentPos: currentPos || '',
    position: position || '',
    totalInstallation: totalInstallation || '',
    isEmailVerified: isEmailVerified || false,
  }),
  validationSchema: yup.object().shape({
    fullName: yup.string().required('Please enter your Name'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
    businessName: yup.string().required('Please enter your Business Name'),
    website: yup
      .string()
      .url('Please enter correct url')
      .required('Please enter your website'),
    countryName: yup.string().required('Please enter your country'),
    phone: yup
      .number()
      .typeError('Please enter only number')
      .integer('Please enter only number')
      .required('Please enter your phone'),
    street: yup.string().required('Please enter your street address'),
    position: yup.string().required('Please enter your position'),
    city: yup.string().required('Please enter your city'),
    state: yup.string().required('Please enter your state'),
    postalCode: yup
      .number()
      .typeError('Please enter only number')
      .required('Please enter your postal code'),
    currentPos: yup.string().required('Please enter your current pos'),
    totalInstallation: yup
      .number()
      .typeError('Please enter only number')
      .required('Please enter your number of installation'),
  }),
  handleSubmit(values, { props: { handleFormData } }) {
    handleFormData(values);
  },
})(BasicInfo);

export default BasicInfoForm;
