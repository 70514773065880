import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import { Image, Menus, Divider, Wrapper } from '../../ui';
import logo from '../../../images/logo.png';
import HeaderWrapper from './style';

const Header = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Container>
          <Grid container alignItems="center">
            <Grid item sm={3}>
              <Link to="/">
                <Image width="150px" src={logo} alt="Orocube.com" />
              </Link>
            </Grid>
            <Grid item sm={9}>
              <Wrapper
                display={{ xs: 'none', md: 'flex' }}
                justifyContent="flex-end"
              ></Wrapper>
            </Grid>
          </Grid>
        </Container>
      </HeaderWrapper>
      <Divider />
    </Wrapper>
  );
};

export default Header;
