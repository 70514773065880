import React from 'react';
import { Typography, Wrapper } from '../';
import styled from 'styled-components';

const HeadingWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  h2 {
    position: relative;
    top: -40px;
  }
`;

const Heading = ({ children }) => {
  return (
    <Wrapper display="flex" justifyContent="center" mt={6}>
      <HeadingWrapper>
        <Typography variant="h2">{children}</Typography>
      </HeadingWrapper>
    </Wrapper>
  );
};

export default Heading;
