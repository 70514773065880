import React from 'react';
import styled from 'styled-components';
import { Typography, Wrapper, Image } from '../../ui';
import star from '../../../images/star.png';
import partner0 from '../../../images/partner-1.png';
import partner1 from '../../../images/partner-2.png';
import partner2 from '../../../images/partner-3.png';

const List = styled.ul`
  margin-top: 20px;
  margin-left: 30px;

  li {
    list-style-image: url(${star});
    margin-bottom: 10px;
  }
`;

const PartnerItem = ({ partner = {}, index }) => {
  return (
    <Wrapper mb={{ xs: 8, md: 0 }}>
      <Wrapper display="flex" alignItems="center">
        <Image
          src={
            index === 0
              ? partner0
              : index === 1
              ? partner1
              : index === 2
              ? partner2
              : ''
          }
          mr={2}
          width={index === 2 ? '20px' : '30px'}
          height={index === 2 ? '25px' : 'auto'}
        />
        <Typography fontSize="20px" variant="h3">
          {partner.title}
        </Typography>
      </Wrapper>
      <List dangerouslySetInnerHTML={{ __html: partner.description }} />
    </Wrapper>
  );
};

export default PartnerItem;
