import React from 'react';
import { Grid, ButtonBase } from '@material-ui/core';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Image, Paper, Wrapper } from '../../ui';
import styled, { css } from 'styled-components';

const BusinessTypeWrapper = styled(({ isActive, ...rest }) => (
  <ButtonBase {...rest} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: #3498db 0px 0px 10px;
    `}

  img {
    width: 50px;
  }

  :hover {
    box-shadow: #3498db 0px 0px 10px;
  }
`;

const BusinessType = ({ formData = {}, section = {}, handleFormData }) => {
  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '70%' }}>
      <Form style={{ marginBottom: 150 }}>
        <Typography variant="body1" align="center" mb={4}>
          {section.subtitles && section.subtitles[0]}
        </Typography>
        <Grid container spacing={8}>
          {section.links &&
            section.links
              .filter((businessTypes) => businessTypes.url)
              .map((businessType) => (
                <Grid item xs={12} md={3} key={businessType.label}>
                  <Paper>
                    <BusinessTypeWrapper
                      isActive={
                        Number(formData.businessType) === businessType.label
                      }
                      onClick={() =>
                        handleFormData({ businessType: businessType.label })
                      }
                    >
                      <Image src={businessType.url} alt={businessType.label} />
                      <Typography variant="body1" mt={2}>
                        {businessType.label}
                      </Typography>
                    </BusinessTypeWrapper>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Form>
    </Wrapper>
  );
};

const BusinessTypeForm = withFormik({
  mapPropsToValues: ({ formData: { businessType } }) => ({
    businessType: businessType || '',
  }),
  validationSchema: yup.object().shape({
    businessType: yup.number().required('Please enter your Business Type'),
  }),
})(BusinessType);

export default BusinessTypeForm;
