import React from 'react';
import { Wrapper, Heading } from '../../ui';
import { Container } from '@material-ui/core';
import Testimonial from './Testimonial';
import AuthorImage from './AuthorImage';

const Testimonials = ({ section = {} }) => {
  return (
    <Wrapper py={10}>
      <Container>
        <Heading>{section.title}</Heading>
        <Testimonial
          testimonial={section.subSections && section.subSections[0]}
        />
        <AuthorImage
          authorImage={
            section.subSections &&
            section.subSections[0] &&
            section.subSections[0].links &&
            section.subSections[0].links[0].url
          }
        />
      </Container>
    </Wrapper>
  );
};

export default Testimonials;
