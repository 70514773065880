export const sendGTMEvent = ({
  action = 'click',
  label,
  value = 1,
  category = 'Download',
}) => {
  typeof window !== 'undefined' &&
    window.gtag &&
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
};
