import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import { Typography, Button, Wrapper } from '../../ui';
import { DownloadIcon } from '../../ui/icons';
import { sendGTMEvent } from '../../../utils/gTag';

const DownloadFloreant = ({ section = {}, product }) => {
  const handleGTMEvent = (label) => {
    sendGTMEvent({
      label,
    });
  };

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '60%' }}>
      <Wrapper>
        <Typography variant="h4" mb={2} align="center">
          {section && section.title}
        </Typography>
        <Typography variant="subtitle1" mb={2} align="center">
          {section && section.subtitles && section.subtitles[0]}
        </Typography>
        <Wrapper
          mx="auto"
          width={{ xs: '100%', md: product === 'both' ? '100%' : '70%' }}
          textAlign="center"
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: '#95A5A6' }}>Product</TableCell>
                  <TableCell style={{ color: '#95A5A6' }}>
                    Download Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {section.links &&
                  section.links.map((link) => (
                    <TableRow key={link.label}>
                      <TableCell style={{ width: '60%' }}>
                        <Typography>{link.label}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Button
                          href={link.url}
                          color="secondary"
                          variant="outlined"
                          onClick={() => handleGTMEvent(link.label)}
                        >
                          <DownloadIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

export default DownloadFloreant;
