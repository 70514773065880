import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { spacing, typography } from '@material-ui/system';

const TypographyExtended = styled(({ align, ...rest }) => (
  <Typography {...rest} />
))`
  text-align: ${({ align }) => (align ? align : 'inherit')};

  span {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${spacing}
  ${typography}
`;

export default TypographyExtended;
