import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { CardSecondary, Wrapper } from '../../ui';

const WhyChoose = ({ section = {} }) => {
  return (
    <Wrapper bgColor="#FFFCF4" card>
      <Container>
        <Grid container spacing={4}>
          {section.subSections &&
            section.subSections.map((subSection, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Wrapper mt={{ md: '-100px' }}>
                  <CardSecondary content={subSection} />
                </Wrapper>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default WhyChoose;
