import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { Wrapper, Typography, Image, RichText } from '../../ui';
import { Level1Wrapper, Level2Wrapper, Level3Wrapper } from './style';
import quote from '../../../images/quote.png';

const Testimonial = ({ testimonial = {} }) => {
  return (
    <Level1Wrapper>
      <Level2Wrapper />
      <Level3Wrapper />
      <Wrapper p={{ xs: 2, sm: 4, md: 10 }}>
        <RichText fontStyle="italic" html={testimonial.description} />
        <Grid container spacing={2}>
          <Hidden mdUp>
            <Grid item xs={3}>
              <Image
                center
                mt={6}
                src={testimonial}
                alt="testimonial"
                width="80px"
              />
            </Grid>
          </Hidden>
          <Grid item xs={9} md={8}>
            <Typography variant="h4" mt={6}>
              {testimonial.title}
            </Typography>
            <Typography variant="subtitle2" mt={2}>
              {testimonial.subtitles && testimonial.subtitles[0]}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={4}>
              <Wrapper
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
                height="100%"
              >
                <Image
                  width={{ xs: 30, md: 80 }}
                  height={{ xs: 30, md: 80 }}
                  src={quote}
                  alt="Quote Image"
                />
              </Wrapper>
            </Grid>
          </Hidden>
        </Grid>
      </Wrapper>
    </Level1Wrapper>
  );
};

export default Testimonial;
