import React, { useState } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { withFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  FieldErrorMsg,
  Select,
  Button,
  Wrapper,
  Typography,
} from '../../ui';
import { useInterval } from '../../../hooks';

const prepareOptions = (countries) => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
      dialCode: country.dialCode,
    };
  });
};

const Verify = ({
  country,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleBack,
  handleSendCode,
  handleVerifyCode,
  codeMatchError,
  handleFormData,
  isVerifyingPhone,
  isSendingCode,
  setFieldTouched,
  setFieldBlur,
  section = {},
}) => {
  const [dialCode, setDialCode] = useState('+1');
  const [sentCode, isSentCode] = useState(false);
  const [disableCodeBtn, setDisableCodeBtn] = useState(false);
  const [timer, setTimer] = useState(60);

  const handleCountryChange = (option) => {
    setDialCode(option.dialCode);
    setFieldValue('countryName', option.value);
  };

  const sendPin = () => {
    const { countryName, phone } = values;

    if (!countryName || !phone) {
      setFieldTouched('countryName', true);
      setFieldTouched('phone', true);
    } else {
      isSentCode(true);
      setDisableCodeBtn(true);

      handleSendCode({
        countryName: countryName,
        phone: phone,
      });
    }
  };

  const sendVerifyCode = () => {
    const { code } = values;

    if (!code) {
      setFieldTouched('code', true);
    } else {
      handleVerifyCode(values.code);
    }
  };

  useInterval(
    () => {
      if (timer === 0) {
        setDisableCodeBtn(false);
        setTimer(60);
      } else {
        setTimer(timer - 1);
      }
    },
    disableCodeBtn ? 1000 : null
  );

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '60%' }}>
      <Wrapper width="80%" mx="auto">
        <Typography variant="body1" align="center" mb={4}>
          {section && section.subtitles && section.subtitles[0]}
        </Typography>
      </Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            options={prepareOptions(country.data || [])}
            placeholder="Country"
            mr={1}
            name="countryName"
            onBlur={setFieldBlur}
            onChange={handleCountryChange}
            defaultValue={{
              label: 'United States',
              value: 'United States',
            }}
          />
          <FieldErrorMsg
            error={errors.countryName}
            isTouched={touched.countryName}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={values.phone}
            size="small"
            pl={1}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phone && touched.phone}
            variant="outlined"
            color="secondary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{dialCode}</InputAdornment>
              ),
            }}
          />
          <FieldErrorMsg error={errors.phone} isTouched={touched.phone} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="secondary"
            disabled={isSendingCode || disableCodeBtn}
            loading={isSendingCode}
            onClick={sendPin}
          >
            {sentCode ? 'Resend Code' : 'Send Code'}
          </Button>
          <Typography variant="subtitle1">
            {disableCodeBtn && `Resend again in ${timer} seconds`}
          </Typography>
        </Grid>

        {sentCode && (
          <>
            <Wrapper width="100%" display="flex" justifyContent="center" mt={6}>
              <TextField
                label="Code"
                name="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.code && touched.code}
                variant="outlined"
                size="small"
                color="secondary"
              />

              <Button
                ml={1}
                color="primary"
                variant="contained"
                onClick={sendVerifyCode}
                disabled={isVerifyingPhone}
                loading={isVerifyingPhone}
              >
                Verify
              </Button>
            </Wrapper>

            <Wrapper width="100%" display="flex" justifyContent="center" mt={1}>
              <FieldErrorMsg error={errors.code} isTouched={touched.code} />
              <Typography variant="body1" color="error">
                {codeMatchError}
              </Typography>
            </Wrapper>
          </>
        )}
      </Grid>
      <Wrapper display="flex" justifyContent="space-between" mt={8}>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
      </Wrapper>
    </Wrapper>
  );
};

const VerifyForm = withFormik({
  mapPropsToValues: ({ formData: { phone, countryName } }) => ({
    phone: phone || '',
    countryName: countryName || 'United States',
    code: '',
  }),
  validationSchema: yup.object().shape({
    phone: yup
      .number()
      .typeError('Please enter only number')
      .integer('Please enter only number')
      .required('Please enter your phone'),
    countryName: yup.string().required('Please enter country'),
    code: yup
      .number()
      .typeError('Please enter only number')
      .positive('Please provide positive number')
      .required('Please enter your verify code'),
  }),
})(Verify);

export default VerifyForm;
