import React from 'react';
import { Image } from '../../ui';
import { ImageSection } from './style';

const AuthorImage = ({ authorImage }) => {
  return (
    <ImageSection>
      <Image src={authorImage} alt="testimonial" width="120px" />
    </ImageSection>
  );
};

export default AuthorImage;
