import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Wrapper, Image, Typography, Menus } from '../../ui';
import logo from '../../../images/logo.png';
import Copyright from './Copyright';

const Footer = ({ metaData = {}, menus }) => {
  return (
    <>
      <Wrapper borderTop={1} borderColor="grey.200" py={4}>
        <Container>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Image src={logo} mb={3} width="150px" />
              <Typography mb={1}>
                {metaData.addresses && metaData.addresses[0]}
              </Typography>
              <Typography mb={1}>
                Toll Free :{' '}
                <span>{metaData.phones && metaData.phones[0].value}</span>{' '}
              </Typography>
              <Typography mb={1}>
                E-mail:{' '}
                <span>{metaData.emails && metaData.emails[0].value} </span>{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Wrapper
                display="flex"
                justifyContent="center"
                mt={{ xs: 3, md: 0 }}
              >
                <Menus list items={menus} />
              </Wrapper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Wrapper
                display="flex"
                justifyContent={{ xs: 'center', md: 'flex-end' }}
                mt={{ xs: 3, md: 0 }}
              >
                {/* <SocialIcons icons={socials} /> */}
              </Wrapper>
            </Grid>
          </Grid>
        </Container>
      </Wrapper>
      <Copyright copyright={metaData.copyrights && metaData.copyrights[0]} />
    </>
  );
};

export default Footer;
