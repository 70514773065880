import React from 'react';
import { Typography } from '../';

const FieldErrorMsg = ({ error, isTouched, message }) => {
  return (
    <>
      {isTouched && error && (
        <Typography variant="subtitle1" color="error" align="left">
          {message || error}
        </Typography>
      )}
    </>
  );
};

export default FieldErrorMsg;
