import React from 'react';

import withSVG from './withSVG';

const DownloadIcon = () => (
  <g>
    <path d="M339.093 246.464A21.357 21.357 0 00320 234.667h-42.667V21.333C277.333 9.557 267.797 0 256 0s-21.333 9.557-21.333 21.333v213.333H192a21.355 21.355 0 00-19.093 11.797c-3.584 7.232-2.816 15.872 2.027 22.336l64 85.333c.277.363.704.491 1.003.832 1.408 1.664 3.072 2.944 4.928 4.117.768.469 1.365 1.088 2.197 1.472 2.731 1.28 5.717 2.112 8.939 2.112s6.208-.832 8.96-2.112c.811-.384 1.429-1.003 2.176-1.472 1.856-1.173 3.52-2.453 4.928-4.117.277-.341.725-.469 1.003-.832l64-85.333a21.31 21.31 0 002.025-22.335z" />
    <path d="M490.667 320c-11.797 0-21.333 9.557-21.333 21.333v64c0 35.285-28.715 64-64 64H106.667c-35.285 0-64-28.715-64-64v-64c0-11.776-9.536-21.333-21.333-21.333C9.536 320 0 329.557 0 341.333v64C0 464.149 47.851 512 106.667 512h298.667C464.149 512 512 464.149 512 405.333v-64c0-11.776-9.536-21.333-21.333-21.333z" />
  </g>
);

export default withSVG(DownloadIcon, { size: 16, viewBox: '0 0 512 512' });
