import React, { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Button, Wrapper, Image } from '../../ui';
import styled from 'styled-components';
import { navigate } from 'gatsby';

const HeroText = styled.p`
  margin-bottom: 0;
  margin-top: 0;
  font-size: 60px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -0.01562em;

  span {
    color: #ffb800;
  }
`;

const HeroSubtitle = styled.p`
  margin-bottom: 0;
  margin-top: 16px;
  font-size: 22px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.01562em;

  span {
    color: #ffb800;
  }
`;

const Hero = ({ section = {} }) => {
  useEffect(() => {
    navigate('/download');
  }, []);

  return (
    <Wrapper py={6}>
      <Container>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Wrapper mb={3}>
              <HeroText>
                Our <span>POS</span> to Thrive your Business
              </HeroText>
              <HeroSubtitle>
                Runs Offline. Connects Cloud. <br /> Online Order Integration.{' '}
              </HeroSubtitle>
            </Wrapper>
            <Wrapper display="flex">
              <Button
                href={section.links && section.links[0].url}
                target="_blank"
                variant="contained"
                color="primary"
                rel="noopener noreferrer"
                mr={2}
              >
                {section.links && section.links[0].label}
              </Button>
              {/* <Button variant="outlined" color="secondary">
                be our partner
              </Button> */}
            </Wrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Wrapper display="flex" justifyContent="flex-end">
              <Image
                width="70%"
                src={section.links && section.links[1].url}
                alt="Our POS to Thrive your Business"
              />
            </Wrapper>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Hero;
