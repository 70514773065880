import React from 'react';
import { Image, Wrapper, Typography, Button, RichText } from '../';

const CardPrimary = ({ content = {} }) => {
  return (
    <Wrapper
      display="flex"
      alignItems="center"
      flexDirection="column"
      py={5}
      px={{ xs: 4, md: 8 }}
      bgcolor="#ffffff"
      borderRadius={10}
      card
      height="100%"
    >
      <Image width="40px" src={content.links && content.links[1].url} mb={2} />
      <Typography variant="h3" my={2} align="center">
        {content.title}
      </Typography>
      <RichText
        style={{ flexGrow: 1 }}
        textAlign="center"
        html={content.description}
      />
      <Button
        href={content.links && content.links[0].url}
        target="_blank"
        rel="noopener noreferrer"
        variant="outlined"
        mt={2}
        color="primary"
        size="small"
      >
        {content.links && content.links[0].label}
      </Button>
    </Wrapper>
  );
};

export default CardPrimary;
