import React from 'react';
import { Container } from '@material-ui/core';
import { Wrapper, Heading, Blog } from '../../ui';
import blogImage1 from '../../../images/blog-1.jpg';
import blogImage2 from '../../../images/blog-2.jpg';

const blogs = [
  {
    image: blogImage1,
    heading: 'New Release',
    desc:
      'Update your system with the latest version of ORO POS. It added the menu-preloader and caching, and integrated MQQT/ IoT technology for real-time messaging between workstations. You can request a free download here.',
    date: 'November 2, 2018',
  },
  {
    image: blogImage2,
    heading: 'Office in Johnson Square Office Park, GA',
    desc:
      'We have a new office in Atlanta, GA. Located in East Cobb, between Marietta and Roswell, our office is near the intersection of Roswell Rd. (HWY 120) and Johnson Ferry Rd., just past the new Target store. It is only 38 minutes away from Atlanta International Airport.',
    date: 'October 5, 2018',
  },
];

const Update = () => {
  return (
    <Wrapper py={6}>
      <Container>
        <Heading>Updates</Heading>
        {blogs.map((blog, index) => (
          <Blog key={index} blog={blog} />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Update;
