export const apiEndpoint = process.env.GATSBY_API_ENDPOINT;

export const monthlyAverageOptions = [
  { label: 'Less than $5,000', value: 'Less than $5,000' },
  { label: '$5,000 - $9,999', value: '$5,000 - $9,999' },
  { label: '$10,000 - $29,999', value: '$10,000 - $29,999' },
  { label: '$30,000- $49,999', value: '$30,000- $49,999' },
  { label: '$50,000- $99,999', value: '$50,000- $99,999' },
  { label: '$100,000 - $249,000', value: '$100,000 - $249,000' },
  { label: '$250,000+', value: '$250,000+' },
  { label: 'Unknown or New business', value: 'Unknown or New business' },
];

export const averageTransactionOptions = [
  { label: 'Less than $19', value: 'Less than $19' },
  { label: '$20 - $49', value: '$20 - $49' },
  { label: '$50 - $99', value: '$50 - $99' },
  { label: '$100+', value: '$100+' },
  { label: 'Unknown or New business', value: 'Unknown or New business' },
];
