import React from 'react';
import {
  Grid,
  ButtonBase,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
} from '@material-ui/core';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import { Typography, Paper, Button, Wrapper, Dialog } from '../../ui';
import styled, { css } from 'styled-components';
import { useToggle } from '../../../hooks';

const products = [
  {
    id: 'both',
    title: 'ORO POS & Floreant POS',
    content: 'Full featured & Basic Open Source Point of Sale',
  },
  {
    id: 'oropos',
    title: 'ORO POS',
    content: 'Full featured Point of Sale with Support and Upgrade',
  },
  {
    id: 'floreant',
    title: 'Floreant POS',
    content: 'Basic Open Source Point of Sale',
  },
];

const ProductTypeWrapper = styled(({ isActive, ...rest }) => (
  <ButtonBase {...rest} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: #3498db 0px 0px 10px;
    `}

  img {
    width: 50px;
    height: 60px;
  }

  :hover {
    box-shadow: #3498db 0px 0px 10px;
  }
`;

const TableHeader = styled(TableHead)`
  background-color: #eeeeee;
`;

const ProductType = ({
  formData = {},
  section = {},
  handleFormData,
  handleBack,
  handleNext,
}) => {
  const [open, setOpen] = useToggle(false);

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '70%' }}>
      <Form style={{ marginBottom: 150 }}>
        <Typography variant="body1" align="center" mb={4}>
          {section.subtitles && section.subtitles[0]}
        </Typography>
        <Grid container spacing={8} justify="center">
          {products.map((productType) => (
            <Grid item xs={12} md={4} key={productType.title}>
              <Paper>
                <ProductTypeWrapper
                  isActive={
                    formData.product === productType.title.toLowerCase()
                  }
                  onClick={() =>
                    handleFormData({
                      product: productType.id,
                    })
                  }
                >
                  <Typography variant="h3" mt={2}>
                    {productType.title}
                  </Typography>
                  <Typography variant="body1" mt={2}>
                    {productType.content}
                  </Typography>
                </ProductTypeWrapper>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="h4"
          style={{ cursor: 'pointer', textAlign: 'center' }}
          onClick={setOpen}
          mt={10}
          mb={-8}
        >
          Check out comparison
        </Typography>

        <Wrapper display="flex" justifyContent="space-between" mt={8}>
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" color="secondary" onClick={handleNext}>
            Next
          </Button>
        </Wrapper>

        <Dialog open={open} handleClose={setOpen} dialogActions={'.'}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHeader>
                <TableRow>
                  <TableCell>Features</TableCell>
                  <TableCell>ORO POS</TableCell>
                  <TableCell>Floreant POS</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>Store setup</TableCell>
                  <TableCell>
                    <Typography>🗹 Store open & End of Day report</Typography>
                    <Typography>🗹 Drawer & Staff bank Report</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>☐ EoD Store report</Typography>
                    <Typography>🗹 Drawer Report</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Printer</TableCell>
                  <TableCell>
                    <Typography>🗹 58mm 🗹 76mm 🗹 80mm Paper</Typography>
                    <Typography>🗹 Receipt Designer</Typography>
                    <Typography>🗹 ESC/POS &</Typography>
                    <Typography>🗹 Jasper Printing</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>🗹 80 mm Paper</Typography>
                    <Typography>🗹 Fixed Printer layout</Typography>
                    <Typography>🗹 Jasper Printer</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell>
                    <Typography>🗹 Multiple Tax per Item </Typography>
                    <Typography>🗹 Store wise Tax</Typography>
                    <Typography>🗹 Tax exemption</Typography>
                    <Typography>🗹 Tax in modifier option</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>🗹 Single Tax per Item</Typography>
                    <Typography>☐ Store wise tax</Typography>
                    <Typography>☐ Tax exemption</Typography>
                    <Typography>☐ Modifier Tax option</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Menu</TableCell>
                  <TableCell>
                    <Typography>🗹 Visual Menu Designer</Typography>
                    <Typography>🗹 Import from Excel</Typography>
                    <Typography>🗹 Search Items</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>🗹 Fixed Menu </Typography>
                    <Typography>☐ menu import</Typography>
                    <Typography>☐ Product search</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kitchen</TableCell>
                  <TableCell>
                    <Typography>🗹 Kitchen Display with Bump Bar </Typography>
                    <Typography>🗹 Kitchen Void</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>🗹 Kitchen Display</Typography>
                    <Typography>☐ Void in KDS</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Other</TableCell>
                  <TableCell>
                    <Typography>🗹 Gift Card generator</Typography>
                    <Typography>🗹 Customer-facing display</Typography>
                    <Typography>🗹 Can Integrate with Online Order</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>☐ Gift card</Typography>
                    <Typography>☐ Customer-facing Display</Typography>
                    <Typography>☐ Online Order</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>
      </Form>
    </Wrapper>
  );
};

const ProductTypeForm = withFormik({
  mapPropsToValues: ({ formData: { productType } }) => ({
    productType: productType || '',
  }),
  validationSchema: yup.object().shape({
    productType: yup.number().required('Please enter your Product Type'),
  }),
})(ProductType);

export default ProductTypeForm;
