import React from 'react';
import { Grid } from '@material-ui/core';
import { Wrapper, Typography } from '../';

const Blog = ({ blog: { image, heading, desc, date } }) => {
  return (
    <Wrapper
      mb={2}
      border="1px solid"
      borderColor="grey.200"
      borderRadius={6}
      card
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Wrapper height="200px" bgImage={image}></Wrapper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Wrapper p={2}>
            <Typography mb={1} variant="subtitle1" fontStyle="italic">
              {date}
            </Typography>
            <Typography variant="h3" mb={2}>
              {heading}
            </Typography>
            <Typography variant="body1">{desc}</Typography>
          </Wrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default Blog;
