import React from 'react';
import styled from 'styled-components';
import { palette, spacing, typography, borders } from '@material-ui/system';

const Article = styled.article`
  ${spacing};
  ${palette};
  ${borders};
  ${typography};

  p {
    font-size: 1rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin: 0;
  }
`;

export default function RichText({ html, ...rest }) {
  return <Article {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
}
