import React from 'react';
import WhoWeAreWrapper from './style';
import { Container, Grid } from '@material-ui/core';
import { Heading, CardPrimary } from '../../ui';

const WhoWeAre = ({ section = {} }) => {
  return (
    <WhoWeAreWrapper>
      <Container>
        <Heading>{section.title}</Heading>
        <Grid container spacing={4}>
          {section.subSections &&
            section.subSections.map((subSection, index) => (
              <Grid item xs={12} md={4} key={index}>
                <CardPrimary content={subSection} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </WhoWeAreWrapper>
  );
};

export default WhoWeAre;
