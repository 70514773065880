import React from 'react';
import { Wrapper, Typography, Button, Image, RichText } from '../../ui';
import { Grid } from '@material-ui/core';

const SecondaryProduct = ({ product = {}, index }) => {
  return (
    <Wrapper
      bgColor="light"
      borderTop="1px solid"
      py={4}
      px={{ xs: 0, md: 2 }}
      my={2}
      borderColor="grey.200"
      card
      boxShadow={2}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={2}>
          <Image
            width="100px"
            center
            src={product.links && product.links[1].url}
            alt={product.title}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" mb={2}>
            {product.title}
          </Typography>
          <RichText html={product.description} mb={0} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Wrapper
            display="flex"
            alignItems="flex-start"
            justifyContent={{ xs: 'center' }}
            height="100%"
            flexDirection="column"
          >
            {index === 0 && (
              <>
                <Typography variant="body1">
                  {product.subtitles && product.subtitles[1]}
                </Typography>
                <Wrapper display="flex" mb={2}>
                  <Typography
                    variant="body1"
                    mr={1}
                    fontSize="25px"
                    fontWeight={700}
                    style={{ color: '#646464' }}
                  >
                    {product.subtitles && product.subtitles[2]} /
                  </Typography>
                  <Typography fontSize="20px" style={{ color: '#646464' }}>
                    {product.subtitles && product.subtitles[3]}
                  </Typography>
                </Wrapper>
              </>
            )}
            <Button
              href={product.links && product.links[0].url}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
            >
              {product.links && product.links[0].label}
            </Button>
          </Wrapper>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SecondaryProduct;
