import React from 'react';
import { Wrapper, Typography } from '../../ui';

const Copyright = ({ copyright }) => {
  return (
    <Wrapper py={2} mt={2} borderTop={1} borderColor="grey.200">
      <Typography align="center" variant="subtitle1">
        Copyright © {new Date().getFullYear()} <span>{copyright}</span>{' '}
      </Typography>
    </Wrapper>
  );
};

export default Copyright;
