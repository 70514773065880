import React from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import { Typography, Button, Wrapper } from '../../ui';
import { DownloadIcon } from '../../ui/icons';
import { sendGTMEvent } from '../../../utils/gTag';

const DownloadOropos = ({
  section = {},
  pin = '',
  email,
  product,
  gotToVerifyStep,
}) => {
  const handleGTMEvent = (label) => {
    sendGTMEvent({
      label,
    });
  };

  return (
    <Wrapper mx="auto" width={{ xs: '100%', md: '60%' }}>
      <Wrapper>
        {pin && (
          <>
            <Typography variant="h2" mb={2} align="center">
              To Activate ORO POS demo, use PIN <span>{pin}</span>
            </Typography>
            <Typography variant="body1" mb={1} align="center">
              for this email: <strong>{email}</strong>
            </Typography>
          </>
        )}

        <Typography variant="h4" mt={pin ? 4 : 0} mb={1} align="center">
          {section && section.title}
        </Typography>
        <Typography variant="subtitle1" mb={2} align="center">
          {section && section.subtitles && section.subtitles[0]}
        </Typography>
        <Wrapper
          mx="auto"
          width={{ xs: '100%', md: product === 'both' ? '100%' : '70%' }}
          textAlign="center"
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: '#95A5A6' }}>Product</TableCell>
                  <TableCell style={{ color: '#95A5A6' }}>
                    Download Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {section.links &&
                  section.links.map((link) => (
                    <TableRow key={link.label}>
                      <TableCell style={{ width: '60%' }}>
                        <Typography>{link.label}</Typography>
                      </TableCell>
                      <TableCell scope="row">
                        <Button
                          href={link.url}
                          color="secondary"
                          variant="outlined"
                          onClick={() => handleGTMEvent(link.label)}
                        >
                          <DownloadIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Wrapper>
        {/* {!pin && (
          <Wrapper textAlign="center" mt={4}>
            <Typography variant="h6" align="center" mb={1}>
              ORO POS requires you to verify your phone to receive activation{' '}
              <span>PIN</span>
            </Typography>
            <Button
              onClick={gotToVerifyStep}
              ml={1}
              variant="contained"
              color="primary"
              size="small"
            >
              click here to get PIN
            </Button>
          </Wrapper>
        )} */}
      </Wrapper>
    </Wrapper>
  );
};

export default DownloadOropos;
