import { useState } from 'react';
import { useFetch } from './';

export default function useEmailVerify() {
  const [data, setData] = useState({});
  const [error, setError] = useState('Please enter valid email');
  const [loading, setLoading] = useState(false);
  const verifyEmail = useFetch('verify/verify-email', 'POST');

  const handleVerify = async (email = '') => {
    setLoading(true);

    try {
      const { data, status } =
        (await verifyEmail.sendReq({
          email,
        })) || {};

      if (status === 200) {
        setData(data);

        if (data.emailRejected) {
          setError(data.suggestion || 'Please enter valid email');
        }
      }

      setLoading(false);
    } catch (err) {
      console.lor(err);
    }
  };

  return {
    emailData: data,
    handleEmailVerify: handleVerify,
    emailError: error,
    emailVerifyLoading: loading,
  };
}
