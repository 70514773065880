import React from 'react';
import { DownloadFloreant, DownloadOropos, DownloadBoth } from './';

export default function DownloadProduct({
  product,
  oroposSection = {},
  floreantSection = {},
  gotToVerifyStep,
  pageData = {},
  ...rest
}) {
  function getDownload(product = '') {
    switch (product) {
      case 'oropos':
        return (
          <DownloadOropos
            gotToVerifyStep={gotToVerifyStep}
            section={pageData?.sections[5]}
            {...rest}
          />
        );
      case 'floreant':
        return <DownloadFloreant section={pageData?.sections[6]} {...rest} />;
      default:
        return (
          <DownloadBoth
            gotToVerifyStep={gotToVerifyStep}
            oroposSection={pageData?.sections[5]}
            floreantSection={pageData?.sections[6]}
            {...rest}
          />
        );
    }
  }

  return getDownload(product);
}
