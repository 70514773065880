import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CloseIcon } from '../icons';
import { Typography, Wrapper, Button } from '../';

const DialogHeader = ({ children, onClose, ...rest }) => {
  return (
    <DialogTitle disableTypography {...rest}>
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="h6">{children}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Wrapper textAlign="right">
            {onClose && (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Wrapper>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

const DialogExtended = ({
  open,
  heading,
  children,
  loading,
  btnDisabled,
  handleClose,
  handleSave,
  handleReject,
  dialogActions,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogHeader id="customized-dialog-title" onClose={handleClose}>
        {heading}
      </DialogHeader>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions style={{ padding: 16 }}>
        {dialogActions ? (
          dialogActions
        ) : (
          <>
            <Button
              variant="text"
              onClick={handleReject}
              color="primary"
              loading={loading}
              disabled={btnDisabled}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              color="primary"
              loading={loading}
              disabled={btnDisabled}
            >
              Save
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogExtended;
