import styled from 'styled-components';
import media from '../../../styles/mediaQuery';

const Level1Wrapper = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-bottom: 50px;

  ${media.md`
    width: 70%;
  `}
`;

const Level2Wrapper = styled.div`
  box-shadow: 5px 8px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: absolute;
  top: 7px;
  left: 7px;
  width: 100%;
  height: 100%;
`;

const Level3Wrapper = styled.div`
  box-shadow: 5px 8px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100%;
  height: 100%;
`;

const ImageSection = styled.div`
  width: 70%;
  margin: 0 auto;
  position: relative;
  top: -85px;
  left: 80px;
  display: none;

  ${media.md`
    display: block;
  `}
`;

export { Level1Wrapper, Level2Wrapper, Level3Wrapper, ImageSection };
