import React from 'react';
import { DownloadFloreant, DownloadOropos } from './';
import { Box } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const DownloadBoth = ({
  gotToVerifyStep,
  oroposSection = {},
  floreantSection = {},
  ...rest
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="ORO POS" />
        <Tab label="Floreant POS" />
      </Tabs>
      <Box flexGrow={1}>
        <TabPanel value={value} index={0}>
          <DownloadOropos
            gotToVerifyStep={gotToVerifyStep}
            section={oroposSection}
            product="both"
            {...rest}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DownloadFloreant
            section={floreantSection}
            product="both"
            {...rest}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default DownloadBoth;
