import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { Heading, Image, Typography, Wrapper, RichText } from '../../ui';
import img from '../../../images/our-company.png';

const OurCompany = ({ section = {}, nextSection = {} }) => {
  return (
    <Wrapper pt={6} mt="-400px" pb={{ xs: 3, md: '120px' }}>
      <Container>
        <Heading>{section.title}</Heading>
        <Image src={img} mb={4} />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h2"
              color="primary"
              mb={3}
              mt={{ xs: 6, md: 0 }}
            >
              {section.subtitles && section.subtitles[0]}
            </Typography>
            <Typography variant="h3" mb={3}>
              {section.subtitles && section.subtitles[1]}
            </Typography>
            <Typography variant="h3" fontWeight={400}>
              {section.subtitles && section.subtitles[2]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <RichText html={section.description} />
          </Grid>
        </Grid>
        <Wrapper mt={15}>
          <Heading>{nextSection.title}</Heading>
        </Wrapper>
      </Container>
    </Wrapper>
  );
};

export default OurCompany;
