import React, { useState, useEffect } from 'react';
import { Container, Stepper, Step, StepLabel } from '@material-ui/core';
import { Wrapper, Typography } from '../../ui';
import { useFetch } from '../../../hooks';
import {
  BasicInfo,
  BusinessType,
  DownloadProduct,
  ProductType,
  Verify,
} from './';
import { navigate } from 'gatsby';
import queryString from 'query-string';

const stepProgress = [1, 2, 3, 4];
const syncSteps = [1, 2, 4, 5];

export default function Download({ sections = [], location }) {
  const [codeMatchError, setCodeMatchError] = useState('');
  const [isAllowedCountry, setIsAllowedCountry] = useState(true);
  const [formData, setFormData] = useState({});
  const [lead, setLead] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [banned, setBanned] = useState(false);

  const fetchCountry = useFetch('countries');
  const fetchRestricted = useFetch('restricted');
  const checkIsFromAllowedCountries = useFetch(
    'restricted/is-from-allowed-countries'
  );
  const createLead = useFetch('leads', 'POST');
  const verifyPhone = useFetch('verify/verify-phone', 'POST');
  const updateLead = useFetch(`leads/${lead.id}`, 'PUT');
  const notifyZD = useFetch(`zd/leads/${lead.id}`, 'POST');
  const sendCode = useFetch('verify/send-code-phone', 'POST');
  const sendPin = useFetch('email/send-pin', 'POST');
  const getSiteInfo = useFetch('sites/orocube');
  const [siteData, setSiteData] = useState({});

  const query = queryString.parse(location.search);
  const activeStep = Number(query.step) || 1;

  async function getRestricted(value = '') {
    const response = await fetchRestricted.sendReq(
      null,
      value ? `/${value}` : ''
    );
    if (!response || response?.data > 0) {
      setBanned(true);
    }

    return response;
  }

  async function checkIsVerifyPhone() {
    const data = await checkIsFromAllowedCountries.sendReq();

    if (data && data.status === 200) {
      if (data.data.isVpn) {
        setIsAllowedCountry(data.data.isVpn);
        return;
      }
      setIsAllowedCountry(data.data.isAllowed);
    }
  }

  async function fetchSiteInfo() {
    const data = await getSiteInfo.sendReq();

    if (data && data.status === 200) {
      setSiteData(data.data);
    }
  }

  useEffect(() => {
    setCurrentStep(activeStep);
    getRestricted();
    checkIsVerifyPhone();
    fetchSiteInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lead.product !== 'floreant' && currentStep === 5) {
      sendPin.sendReq({
        fullName: lead.fullName,
        leadId: lead.id,
        email: lead.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    fetchCountry.sendReq();

    if ([1, 2, 3].includes(activeStep)) {
      setFormData(query);
    } else {
      setFormData({
        businessType: query.businessType,
        product: query.product,
        step: 3,
      });
      navigate(
        `${location.pathname}?${queryString.stringify({
          businessType: query.businessType,
          product: query.product,
          step: 3,
        })}`
      );

      setCurrentStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (params) => {
    const step = currentStep + 1;

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...params,
        step: step,
      })}`
    );
    setCurrentStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;
    navigate(
      `${location.pathname}?${queryString.stringify({
        ...formData,
        step: step,
      })}`
    );
    setCurrentStep(step);
  };

  const handleSendCode = async (values) => {
    try {
      const updatedFormData = {
        ...formData,
        ...values,
      };
      setFormData(updatedFormData);
      const phoneResponse = await getRestricted(updatedFormData.phone);

      if (!phoneResponse.data) {
        updateLead.sendReq({
          ...values,
        });

        await sendCode.sendReq(values);
      } else {
        updateLead.sendReq({
          isBanned: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifyCode = async (code) => {
    try {
      const responsePin = await verifyPhone.sendReq({
        countryName: formData.countryName,
        phone: formData.phone,
        email: formData.email,
        code,
      });

      if (responsePin) {
        setCodeMatchError('');
        setLead({
          ...lead,
          status: 'byPhone',
          pin: responsePin.data.pin,
        });

        await updateLead.sendReq({
          ...formData,
          status: 'byPhone',
          pin: responsePin.data.pin,
        });

        if (!lead.isTest) {
          notifyZD.sendReq();
        }

        handleNext(formData);
      } else {
        setCodeMatchError('Code not matched, Please try again');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFormData = async (values) => {
    const updatedFormData = {
      ...formData,
      ...values,
    };
    setFormData(updatedFormData);

    if (syncSteps.includes(currentStep)) {
      handleNext(updatedFormData);
    }

    if (currentStep === 3) {
      try {
        const emailResponse = await getRestricted(updatedFormData.email);
        const phoneResponse = await getRestricted(updatedFormData.phone);

        if (!emailResponse.data && !phoneResponse.data) {
          const lead = await createLead.sendReq({
            ...updatedFormData,
            type: 'DOWNLOAD',
          });

          if (lead.status === 201) {
            setLead(lead.data);

            if (updatedFormData.product !== 'floreant' && isAllowedCountry) {
              handleNext(updatedFormData);
            } else {
              navigate(
                `${location.pathname}?${queryString.stringify({
                  ...updatedFormData,
                  step: 5,
                })}`
              );
              setCurrentStep(5);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const gotToVerifyStep = () => {
    navigate(
      `${location.pathname}?${queryString.stringify({
        ...formData,
        step: 4,
      })}`
    );
    setCurrentStep(4);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return (
          <BusinessType
            section={sections && sections[1]}
            formData={formData}
            handleFormData={handleFormData}
          />
        );
      case 2:
        return (
          <ProductType
            section={sections && sections[2]}
            formData={formData}
            handleFormData={handleFormData}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <BasicInfo
            section={sections && sections[3]}
            formData={formData}
            handleFormData={handleFormData}
            handleNext={handleNext}
            handleBack={handleBack}
            country={fetchCountry}
            isCreatingLead={createLead.loading}
            isAllowedCountry={isAllowedCountry}
          />
        );
      case 4:
        return (
          <Verify
            section={sections && sections[4]}
            country={fetchCountry}
            formData={formData}
            handleSendCode={handleSendCode}
            handleVerifyCode={handleVerifyCode}
            handleBack={handleBack}
            handleNext={handleNext}
            codeMatchError={codeMatchError}
            handleFormData={handleFormData}
            isVerifyingPhone={verifyPhone.loading}
            isSendingCode={sendCode.loading}
          />
        );
      case 5:
        return (
          <DownloadProduct
            product={formData.product}
            email={lead.email}
            pin={lead.pin}
            oroposSection={sections && sections[5]}
            floreantSection={sections && sections[6]}
            gotToVerifyStep={gotToVerifyStep}
            pageData={siteData && siteData.pages ? siteData?.pages[2] : {}}
          />
        );
      default:
        return;
    }
  }

  return (
    <Wrapper my={6}>
      <Container>
        <Wrapper my={4} width={{ xs: '100%', md: '60%' }} mx="auto">
          <Typography variant="h2" align="center" mb={2}>
            {sections && sections[0] && sections[0].title}
          </Typography>
          <Typography variant="body1" align="center" mb={1}>
            {sections && sections[0] && sections[0].subtitles[0]}
          </Typography>
          <Typography variant="body1" align="center">
            {sections && sections[0] && sections[1].subtitles[1]}
          </Typography>
        </Wrapper>
      </Container>
      {!banned ? (
        <>
          <Wrapper width={{ xs: '100%', md: '40%' }} mx="auto">
            <Stepper
              activeStep={currentStep - 1}
              alternativeLabel
              style={{ background: '#fff' }}
            >
              {stepProgress.map((_, index) => (
                <Step key={index}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          </Wrapper>
          <Container>
            <Wrapper mb={6} mt={3}>
              {getStepContent(currentStep)}
            </Wrapper>
          </Container>
        </>
      ) : (
        <Wrapper textAlign="center">
          <Typography variant="h3" color="error" mb={2}>
            Service is currently unavailable.
          </Typography>
          <Typography variant="body1">
            Try again later or contact <strong>helpdesk@orocube.net</strong> or
            call <strong>+1 800-844-6603</strong>
          </Typography>
          <Wrapper
            mt={3}
            borderTop="1px solid"
            borderBottom="1px solid"
            py={2}
            borderColor="grey.300"
          >
            <Typography variant="body1">
              Did you know you can get No Commission Online Ordering in
              <a href="https://menugreat.com/"> Menugreat.com</a>
            </Typography>
          </Wrapper>
        </Wrapper>
      )}
    </Wrapper>
  );
}
