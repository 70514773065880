import React, { useState, useEffect } from 'react';
import { Container, Stepper, Step, StepLabel } from '@material-ui/core';
import { Wrapper, Typography, PreLoader } from '../../ui';
import { useFetch } from '../../../hooks';
import { BasicInfo, BusinessType, Feedback } from './';
import { navigate } from 'gatsby';
import queryString from 'query-string';

const stepProgress = [1, 2];
const syncSteps = [1];

export default function Download({ sections = [], location }) {
  const [formData, setFormData] = useState({});
  const [defaultCountry, setDefaultCountry] = useState();
  const fetchCountries = useFetch('countries');
  const fetchLocation = useFetch('location');
  const createLead = useFetch('zd/partner', 'POST');
  const query = queryString.parse(location.search);
  const activeStep = Number(query.step) || 1;
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setCurrentStep(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCountries.sendReq();
    fetchLocation.sendReq();

    if ([1, 2].includes(activeStep)) {
      setFormData(query);
    } else {
      setFormData({
        businessType: query.businessType,
        step: 2,
      });
      navigate(
        `${location.pathname}?${queryString.stringify({
          businessType: query.businessType,
          step: 2,
        })}`
      );

      setCurrentStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchCountries.data && fetchLocation.data) {
      const country = fetchCountries.data.find(
        (country) => country.ISOCode === fetchLocation.data.countryCode
      );
      setDefaultCountry({
        label: country.name,
        value: country.name,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCountries.data, location.data]);

  const handleNext = (params) => {
    const step = currentStep + 1;

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...params,
        step: step,
      })}`
    );

    setCurrentStep(step);
  };

  const handleBack = () => {
    const step = currentStep - 1;

    navigate(
      `${location.pathname}?${queryString.stringify({
        ...formData,
        step: step,
      })}`
    );

    setCurrentStep(step);
  };

  const handleFormData = async (values) => {
    const updatedFormData = {
      ...formData,
      ...values,
    };
    setFormData(updatedFormData);

    if (syncSteps.includes(currentStep)) {
      handleNext(updatedFormData);
    }

    if (currentStep === 2) {
      try {
        const lead = await createLead.sendReq({
          ...updatedFormData,
        });
        if (lead.status === 201) {
          navigate(
            `${location.pathname}?${queryString.stringify({
              ...updatedFormData,
              step: 3,
            })}`
          );

          setCurrentStep(3);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return (
          <BusinessType
            section={sections && sections[1]}
            formData={formData}
            handleFormData={handleFormData}
          />
        );
      case 2:
        return (
          <>
            {fetchCountries.loading || fetchLocation.loading ? (
              <PreLoader />
            ) : (
              <BasicInfo
                section={sections && sections[3]}
                formData={formData}
                countryDefaultOption={defaultCountry}
                handleFormData={handleFormData}
                handleNext={handleNext}
                handleBack={handleBack}
                countries={fetchCountries.data || []}
                location={fetchLocation.data || {}}
                isCreatingLead={createLead.loading}
              />
            )}
          </>
        );
      case 3:
        return <Feedback />;
      default:
        return;
    }
  }

  return (
    <Wrapper my={6}>
      <Container>
        <Wrapper my={4} width={{ xs: '100%', md: '60%' }} mx="auto">
          <Typography variant="h2" align="center" mb={2}>
            Request Partnership
          </Typography>
          <Typography variant="body1" align="center" mb={1}></Typography>
          <Typography variant="body1" align="center">
            {sections && sections[0] && sections[1].subtitles[1]}
          </Typography>
        </Wrapper>
      </Container>
      <Wrapper width={{ xs: '100%', md: '40%' }} mx="auto">
        <Stepper
          activeStep={currentStep - 1}
          alternativeLabel
          style={{ background: '#fff' }}
        >
          {stepProgress.map((_, index) => (
            <Step key={index}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </Wrapper>
      <Container>
        <Wrapper mb={6} mt={3}>
          {getStepContent(currentStep)}
        </Wrapper>
      </Container>
    </Wrapper>
  );
}
