import React from 'react';
import { Wrapper, Typography } from '../';

const CardSecondary = ({ content = {} }) => {
  return (
    <Wrapper borderRadius={10} bgColor="light" card>
      <Wrapper
        bgImage={content.links && content.links[0].url}
        height="180px"
        width="100%"
      ></Wrapper>
      <Wrapper p={2} pb={2}>
        <Typography variant="h3" align="center">
          {content.title}
        </Typography>
      </Wrapper>
    </Wrapper>
  );
};

export default CardSecondary;
