import React from 'react';
import styled, { css } from 'styled-components';
import { spacing } from '@material-ui/system';
import { Link } from 'gatsby';

const Menu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  ${({ list }) =>
    list
      ? css`
          flex-direction: column;
          align-items: flex-start;
        `
      : css`
          flex-direction: row;
          align-items: center;
        `}

  ${spacing};
`;

const MenuItem = styled.li`
  margin-bottom: ${({ list }) => list && '10px'};
`;

const MenuItemLink = styled.a`
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.typography.titleFont};
  padding: ${({ list }) => (list ? '10px' : '0 10px')};
  color: ${({ color }) => (color ? color : '#292E36')};
`;

const MenuItemInterLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${({ theme }) => theme.typography.titleFont};
  padding: ${({ list }) => (list ? '10px' : '0 10px')};
  color: ${({ color }) => (color ? color : '#292E36')};
`;

const Menus = ({ items = [], color, list, ...rest }) => {
  return (
    <Menu list={list && list.toString()} {...rest}>
      {items.map(({ label, url, isActive, isOpenNewTab }) => (
        <MenuItem list={list && list.toString()} key={label}>
          {isActive && (
            <>
              {isOpenNewTab || url.includes('http') || url.includes('www') ? (
                <MenuItemLink
                  list={list && list.toString()}
                  href={url}
                  color={color}
                  target={isOpenNewTab ? '_blank' : ''}
                >
                  {label}
                </MenuItemLink>
              ) : (
                <MenuItemInterLink
                  list={list && list.toString()}
                  to={url}
                  color={color}
                >
                  {label}
                </MenuItemInterLink>
              )}
            </>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default Menus;
